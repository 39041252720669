import { FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { GoogleAnalytics } from '../Services/Analytics/GoogleAnalytics.service';

const HomePage = lazy(() => import('./Pages/Home/HomePage'));
const StudioPage = lazy(() => import('./Pages/Studio/StudioPage'));
const PlacePage = lazy(() => import('./Pages/Place/PlacePage'));
const BookPage = lazy(() => import('./Pages/Book/BookPage'));

export const App: FunctionComponent = () => {
  const analytics: GoogleAnalytics = new GoogleAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.configure();
  }, []);

  useEffect(() => {
    analytics.trackNavigation();
  }, [location]);

  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/studios" element={<StudioPage />} />
        <Route path="/place" element={<PlacePage />} />
        <Route path="/book" element={<BookPage />} />
      </Routes>
    </Suspense>
  );
};
